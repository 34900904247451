@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #app, #app>div {
  height: 100%
}

.root {
  height: 100%;
  width: 100%;
}

.form-group{
  font-size: 0.5em;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.square {
  height: 512px;
  width: 512px;
}

.square2 {
  object-fit: cover
}

#htmlContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
#htmlUi,
#htmlTarget {
  position: absolute;
  top: 0;
  left: 0;
}
#htmlTarget {
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
}
#htmlUi {
  height: auto;
  width: 100%;
  z-index: 10;
  /* background-color : rgba(255,255,255,0.85); */
  
}
#adminpanel{
  height: auto;
}

.bookmarks{
  width: 100vw;
}

.bookmarks2{
  /* width: auto; */
  max-width: 30%;
  min-width: 20%;
  color:white;
  background-color : rgba(0,0,0,0.85);
  position: fixed;
  bottom: 1em;
  left: 1em;
}
.bookmarkeditform {
  background-color: white;
  }
  .bookmarkeditform .input {
    font-size: 0.2em;
    }
.bookmark {
  color:black;
  background-color : rgba(255,255,255,0.85);
}
.tableBodyScroll tbody {
  display: block;
  max-width: 80vw;
  overflow-x: scroll;
}
#bookmarkInfo {
  display: block;
  padding: 1em;
  position: fixed;
  width: 100%;
  max-height: 20vh;
  /* overflow-x: scroll; */
  bottom: 0px;
  z-index: 10;
  
}
#bookmarkInfoTitre {
  font-weight: bold;
  text-align: center;
  margin-left: 1em;
  margin-right: 1em;
}

.slider-all {
  color:white;
  }

.slider-elem{
  display: -webkit-flex; /* Safari */
  -webkit-flex-direction: column; /* Safari 6.1 and newer */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1em;
  
}
.slider-image{
  -webkit-align-self: center; /* Safari 7.0 and newer */
  align-self: center;

}
.slider-image-label{
  text-align: center;
  font-size: 1em;
  color:black;
  height: 1.5em;
  text-overflow: ellipsis; 
  overflow: hidden; 
  white-space: nowrap;
  line-height: normal;  
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.slider-image-label-white{
  text-align: center;
  font-size: 1em;
  color:white;
  height: 1.5em;
  text-overflow: ellipsis; 
  overflow: hidden; 
  white-space: nowrap;
  line-height: normal;  
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.slider-image-label-white2{
  text-align: center;
  font-size: 0.7em;
  color:white;
  height: 1.5em;
  text-overflow: ellipsis; 
  overflow: hidden; 
  white-space: nowrap;
  line-height: normal;  
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
  .bookmarkInfoFooter {
    color:black;
    display: block;
    padding: 1em;
    position: fixed;
    width: 100%;
    max-height: 25vh;
    overflow-y: auto;  
    bottom: 0px;    
    background-color : rgba(255,255,255,0.95);    
  }

  .bookmarkInfoFooter2 {
    color:white;
    display: block;
    padding: 1em;
    position: fixed;
    left: 2.5%;
    width: 95%;
    /* height: 15%; */
    overflow-y: auto;  
    bottom: 1em;    
    background-color : rgba(0,0,0,0.85);    
  }

  .bookmarkInfoTitre {
    font-weight: bold;
    text-align: center;
    margin-top: 0.25em;
    margin-bottom: 1em;
  }

  .bookmarkInfoTitre2 {
    font-weight: bold;
    text-align: center;
    margin-top: 0.25em;
    margin-bottom: 1em;
    font-size: 2em;
  }

  .bookmarkInfoText {

    margin-bottom: 0.5em;
    font-size: 1em;
  }

  .bookmarkaudioFooter {
    color:black;
    display: block;
    position: fixed;
    width: auto;
    bottom: 0px;    
    background-color : rgba(255,255,255,0.95);
    z-index: 1500;
  }

  .closebicon2 {
    margin-left: 3em;
  }
  .nextbicon2 
    {
    margin-right: 3em;
  }

  .nextbicon {
    margin-left: 3em;
  }
  .closebicon 
    {
    margin-right: 3em;
  }

  .panotop {
    color:black;
    display: block;
    padding: 1em;
    width: 100%;
    /* width: 100vw; */
    
    /* overflow-x: scroll; */
    top: 0px;    
    background-color : rgba(255,255,255,1);
    
  }



  .infront{
    display: block;
    padding: 1em;
    position: fixed;
    z-index: 10;
  }
  .full{
    width: 100%;
    height: 100%;
    background-color : rgba(255,255,255,0.95);
  }

  .left50{
    width: 50%;
    height: 100%;
    position: fixed;
    display: block;
    left: 0;
    background-color : rgba(255,255,255,0.95);
  }
  .messageBox{

    color:black;
    background-color :white;
    padding: 20px;
  }
  .darkmessageBox{

    color: rgba(0,0,0,1);
    background-color : rgba(255,255,255,1);
    padding: 20px;
  }
  .photoaccount{
    margin-right: 1em;
  }
  .infopano{
    margin-left: 1em;
    
  }

  .googleicon{
    width:  auto;
    height: 3em;
    background-color: black;
  }
  /* .googleconnectbutton{
/*     color: darkslategray;
    background-color : blue; */
    /* padding: 1em; 
  } */
  .AskIdMessage{
    color:black;
    width:  auto;
    max-width: 50;
    background-color: white;
    padding: 1em;
  }
  .bklogin{
    margin-bottom: 1em;
  }
  .panoinfomessagebox{
    max-width: 97vw;
    max-height: 97vh;
    height: auto;
    overflow-y: auto;    
      
  }
  .panoinfomessagetitre{
    margin: 0.5em;
  }
  .panoinfomessagetext{
    margin: 0.25em;
    max-height: 50vh;
    max-width: 85vw;
    font-size: 1em;
  }
  .panoinfomessageimg{
    margin: 1em;
    max-height: 30vh;
    max-width: 60vw;
    width: auto;
  }
  .audioprogress{
    width: 20vw;
    margin-left: 1em;
    margin-right: 1em;
  }

  .audiotext{
    margin-left: 1em;
    margin-right: 1em;
  }

  #lightbox {
       
    -webkit-transition: opacity 2s;
    -moz-transition: opacity 2s;
    -o-transition: opacity 2s;
    transition: opacity 2s;
 }
 .pinchzoom {
   margin: 0em;
   position: fixed;
      height: 100%;
   width: 100%;
   display: block;
   z-index: 40;
top: 0;
background-color : rgba(255,255,255,0.95);
 }
 .titrelightbox{
   position: fixed;
   display: block;
top: 0;
width: 80vw;
padding:  1em;
z-index: 50;
background-color: white;

 }

 .targetThumb {
  margin: 0em;
  position: fixed;
  margin-top: 20vh;
  height: 50vh;
  width: 50vh;
  display: block;
  z-index: 40;
top: 0;
background-color : rgba(255,255,255,0.95);
}

.targetThumbButton {
  margin-top: 80vh;
  position: fixed;
}

.targetThumbTitre {
  margin-top: 10vh;
  height: 10vh;
  position: fixed;
}

 .panotopback {
   background-color: white;
 }

 .panotopbackhidden {
  background-color: rgba(255,255,255,0);
}

.blackBackground {
  color:white;
  background-color: rgba(0,0,0,1);
}

.whiteBackground {
  background-color: rgba(255,255,255,1);
}

.transparentBackground {
  background-color: rgba(255,255,255,0);
}


.bold {
  font-weight: bold;
}

.padding1em {
  padding: 1em;
}

.padding05em {
  padding: 0.5em;
}

.padding075em {
  padding: 0.75em;
}

.margin1em {
  margin: 1em;
}

.margin05em {
  margin: 0.5em;
}

.margin05emleft {
  margin-left: 0.5em;
}

.margin1emleft {
  margin-left: 1em;
}

.margin2emleft {
  margin-left: 2em;
}

.maxwidth100 {
  max-width: 100%;
}

.full100{
  width: 100%;
  height: 100%;
}

.full100h{
  height: 100%;
}

/* #bookmarkInfoTitre {
  font-weight: bold;
  text-align: center;
  margin-left: 1em;
  margin-right: 1em;
} */
.panoInfoTitre {
  font-weight: bold;
  text-align: center;
  margin-left: 1em;
  margin-right: 1em;
}

.panoInfoTitreSplit {
  font-weight: bold;
  text-align: center;
  margin-left: 1em;
  margin-right: 1em;
  width: 100%;
}

.playerTitre {
  font-weight: bold;
  text-align: center;
}
.maxwidth100vw{
  max-width: 100vw;
}

.maxwidth100{
  max-width: 100%;
}

.disabled{
  color: gray;
}

.whiteBackground{
  background-color : rgba(255,255,255,1);  
}

.placeInfoContainer {
  color:black;
  display: block;
  padding: 1em;
  position: fixed;       
  background-color : rgba(255,255,255,0.95);  
}

.placeInfoRight{
  right:  0px;
  height:  100%;
  /* width: 50%; */
}

.placeInfoBottom{
  bottom:   0px;
  width:  100%;
  height:  60%;
}

.placeInfotitre{
  font-weight: bold;
  text-align: center;
  margin: 0.5em;
}



.placeInfoPhoto{
  height: 95%;
  width: auto;
  max-width: 95%;
  /* max-width: 90%;
  max-height: 60vh;
  margin-left: auto;
  margin-right: auto; */
}

.placeInfoMap{
  height: 95%;
  width: 95%;
  /* padding-top: 1em; */
  /* max-width: 90vh; */
  /* margin-left: auto;
  margin-right: auto; */
  /* max-height: 20vh; */
}

.splitter{
  width: 1em;
  background-color : rgba(255,0,255,0.95);  
}

.fancy-splitter {
  background-color: #ec1494;
  width: 10px !important;
}
  
.reflex-layout.vertical > .reflex-splitter,
  .reflex-layout.vertical > .reflex-splitter:hover{
    border: none;
    height: 100%;
    width: 6px;
  }

  .notclickeable {
    pointer-events:none;        
  }

  .clickeable {
    pointer-events:auto;        
  }

  .clickeableHerit {
    pointer-events:inherit;        
  }

  #htmlUi .panelGroup{
    pointer-events:none;  
    height: 50%;
    z-index: 500;
   }

   .horizontalsplitter{
     height: 3px;
     width: 100%;
     background-color : rgba(0,0,0,0.95);  
   }

 #htmlUi .divider{
  pointer-events:auto;  
  /* max-width: 30px;
  width: 30px; */ }

 .userage{
   background-color: white;
   position: fixed;
   bottom: 0;
 }

 .htmltargetright{
  position: fixed;
  right: 0px;
}

.clickres{
  position: fixed;
  left: 20%;
  top:1em;
  height: 2em;
  width: 2em; 
  z-index: 1500;
} 
.maptopicon{
  margin-left: 1em;
  
}

.titretextembaovelasquez{
  font-family: "Cigra";
  font-size: 2em;
  margin: 0em;
  
}

.titre2textembaovelasquez{
  font-family: "Cigra";
  font-size: 1.5em;
  margin: 0em;
}

tab {
  display: inline-block;
  margin-left: 40px;
  
}

.logobottom {
  margin-bottom: 1em;
  bottom: 1em;
  position: fixed;
  z-index: 2500;
  /* width: 2em;  */
  margin-left: 1em;
}
.logobottomright {
  margin-bottom: 1em;
  bottom: 1em;
  right: 1em;
  position: fixed;
  z-index: 2500;
  /* width: 2em;  */
  margin-right: 1em;
}

.logtoBottomprightsmall {  
  position: fixed;
  width: 10%;
  height: auto;
  right: 1em;
  margin-bottom: 1em;
  bottom: 1em;
  
}

.changeicon {
  top: 15%;
  position: fixed;
  z-index: 2500;
  /* width: 2em;  */
  margin-left: 1em;
  font-weight: bold;
  text-align: center;
  color: white;
}

.rtctopbar {
  color:black;
  display: block;
  position: fixed;
  padding: 1em;
  /* width: 50%; */
  /* height: 20%; */
  /* width: 100vw; */
  
  /* overflow-x: scroll; */
  left: 2em;
  bottom: 20vh;    
  background-color : rgba(255,255,255,1);
  /* margin-bottom: 3em; */
  /* margin-left: 2em; */
  
}

.haircheckclass {
  /* background-color : rgba(255,0,255,0.95);  */
  height: auto;
  /* width: auto; */
  padding-top: 2em;
  padding-bottom: 3em;
  /* padding-right: 20vw; */
  /* padding-left: 2em; */
  /* width: 30%; */
  max-width:80%;
}

.subtitle {
  color:black;
  display: block;
  padding: 1em;
  position: fixed;
  /* width: 100%; */
  max-height: 25vh;
  overflow-y: auto;  
  margin: 1em;
  bottom: 0px;    
  background-color : rgba(255,255,255,0.95);
  font-weight: bold;
  text-align: center;
  
}

.rightjoystick {
  color:black;
  display: block;
  /* padding: 1em; */
  position: fixed;
  /* width: 100%; */
  /* max-height: 25vh; */
  /* overflow-y: auto;   */
  /* margin: 1em; */
  bottom: 3em;
  right: 3em;    
  z-index: 2500;
  /* background-color : rgba(255,255,255,0.95); */
  /* font-weight: bold;
  text-align: center; */
  
}

.leftjoystick {
  color:black;
  display: block;
  /* padding: 1em; */
  position: fixed;
  /* width: 100%; */
  /* max-height: 25vh; */
  /* overflow-y: auto;   */
  /* margin: 1em; */
  bottom: 3em;
  left: 3em;    
  z-index: 2500;
  /* background-color : rgba(255,255,255,0.95); */
  /* font-weight: bold;
  text-align: center; */
  
}

.topright1 {
  
  position: fixed;

  top: 1em;
  right:  1em;    

  
}

.topleft1 {
  
  position: fixed;

  top: 1em;
  left:  1em;    

  
}

.rightcenter1 {
  
  position: fixed;

 
  right:  1em;    
  top: 45vh;

  
}

.centered {
  
  position: fixed;

 
  right:  45%;    
  top: 45%;

  
}

.secondView {
  color:black;
  display: block;
  padding: 1em;
  position: fixed;
  width: 20%; 
  height: 20%;
  margin: 1em;
  bottom:5%;    
  z-index: 1500;

  
}
.stereoView {
  color:black;
  display: block;
  padding: 1em;
  position: fixed;
  width: 50%; 
  height: 100%;
  right:0px;    
  /* z-index: 1500; */

  
}

.thumbnav{
  display: block;
  padding: 1em;
  /* position: fixed; */
  /* width: 10%; */
  height: 200px;
  /* overflow-x: scroll; */
  /* bottom: 0px; */
  /* z-index: 10; */
}

.thumbnavFooter {
  color:white;
  display: block;
  padding: 0.5em;
  position: fixed;
  /* width: 100%; */
  /* max-height: 25vh; */
  /* overflow-y: auto;   */
  right: 1em;
  bottom: 1em;    
  background-color : rgba(0,0,0,0.85);
  
}



.logocenter {
  
  position: fixed;
  margin: auto;
  width: 15%;
  /* right:  1em;     */
  left: 42%;
  top: 1em;

  
}



.logotop {  
  /* position: fixed; */
  width: 100%;
  /* margin: auto; */
  
  /* height: auto; */
  /* right:  1em;     */
  right: 0px;
  top: 1em;  
}

.logotopright {  
  position: fixed;
  width: 10%;
  height: auto;
  right: 1em;
  
}


.righbelowlogo{
  
  position: fixed;

 
  right:  4.5em;    
  top: 15%;
  /* width: 2%; */

  
}

.avatar {
  
  position: fixed;
  margin: auto;
  width: 1%;
  /* right:  1em;     */
  left: 1em;
  top: 40%;

  
}
.arksan {
  
  width: 100%;
  height: 100vh;
  
}

.arksantitre {

  
  
  position: fixed;
  
  width: 100%;
  /* height: auto; */
  /* left: 45%; */
  text-align: center;
  font-weight: bold;

  font-size: 1.5em;
}

.arksantitre2 {

  
  
  position: fixed;
  
  width: 100%;
  /* height: auto; */
  /* left: 45%; */
  text-align: center;
  font-weight: bold;

  
}

.arksansoustitre {
  font-size: 0.5em;
}


.youtube {
	width: 100vw;
	height: 56.25vw;
	min-height: 100vh;
	min-width: 177.78vh;
}

.password {
	width: 100vw;
	height: 100vw;
  background-color : rgba(0,0,0,1);
}

.youtubetitre {

  
  
  position: fixed;
  
  width: 100%;
  /* height: auto; */
  /* left: 45%; */
  text-align: center;
  font-weight: bold;

  font-size: 0.75em;
}
